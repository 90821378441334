import clsx from "clsx"
import React, { createContext, useContext, useMemo } from "react"
import "./Tabs.scss"

const TabContext = createContext()

export const useTabs = () => {
  return useContext(TabContext)
}

export const Tabs = ({
  activeKey,
  setActiveKey,
  children,
  className,
  darkTheme,
  onChange,
  CTA,
}) => {
  const contextValue = useMemo(() => {
    const onTabChange = (key) => {
      setActiveKey(key)
      onChange && onChange(key)
    }
    return {
      activeKey,
      onTabChange,
    }
  }, [activeKey, setActiveKey, onChange])

  return (
    <TabContext.Provider value={contextValue}>
      <div className="tab-header-section">
        <div
          className={clsx(
            "custom-tabs",
            className,
            darkTheme && "custom-tabs-dark"
          )}
        >
          {children}
        </div>
        {CTA && <div className="cta-section">{CTA}</div>}
      </div>
    </TabContext.Provider>
  )
}

export const Tab = ({ eventKey, children, className }) => {
  const { activeKey, onTabChange } = useTabs()

  return (
    <button
      className={clsx(
        "tab-button button",
        activeKey === eventKey ? "selected-tab button-primary" : "button-white",
        className
      )}
      onClick={() => onTabChange(eventKey)}
      type="button"
    >
      {children}
    </button>
  )
}
