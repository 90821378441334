/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"

export const useSubscriberStore = create(
  devtools(
    immer((set) => ({
      subscribers: undefined,
      setSubscribers: (subscribers) => {
        set((state) => {
          state.subscribers = subscribers
        })
      },
    }))
  )
)
